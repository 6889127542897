import React, { useState } from "react";

function ImportComponent() {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [videoSrc, setVideoSrc] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type === "video/mp4") {
      setSelectedFile(file);
      const videoURL = URL.createObjectURL(file);
      setVideoSrc(videoURL);
    } else {
      alert("Please select a mp4 file");
    }
  };

  const handleImport = () => {
    //doesnt do anything right now
    if (selectedFile) {
      console.log("File imported: ", selectedFile.name);
    } else {
      alert("No file selected");
    }
  };
//the video player needs a key tag to update the preview, otherwise itll only show the first uploaded vid
//this is due to how react handles components, check here: https://robfarr.me/blog/react-doesnt-update-html5-video-sources/
// - Oli
  return (
    <div>
      {videoSrc && (
        <div>
          <h3>Video Preview:</h3>
          <video key={videoSrc} width="800vw" height="500vh" controls>
            <source src={videoSrc} type="video/mp4" />
          </video>
        </div>
      )}
      {/*the label is used to hide the default choose file button, since we cant change it directly we have to do it this way*/}
      <label htmlFor="fileInput" className="custom-file-input">Import</label>
      <input  type="file" id = "fileInput" accept="video/mp4" onChange={handleFileChange} />
      {
      /*<button className = "button" onClick={handleImport}>Import</button>
      Doesn't do anything yet
      */}
    </div>
  );
}
export default ImportComponent;
