import React, { useState } from "react";
import "./App.css";

import EditComponent from "./components/ImportComponent";
import SettingsComponent from "./components/SettingsComponent";

function App() {
  const [activeComponent, setActiveComponent] = useState("Edit");

  // Function to render the correct component based on state
  const renderComponent = () => {
    switch (activeComponent) {
      case "Edit":
        return <EditComponent />;
      case "Settings":
        return <SettingsComponent />;
      case "Export":
        return <div>Export</div>;
      default:
        return <EditComponent />;
    }
  };

  return (
    <div className="App">
      <header className="App-header">{renderComponent()}</header>
      <div className="sidebar">
        <h1>VisiTag</h1>
        {/* Sidebar with clickable links */}
        <a
          href="/#"
          className={activeComponent === "Edit" ? "active" : ""}
          onClick={() => setActiveComponent("Edit")}
        >
          Edit
        </a>
        <a
          href="/#"
          className={activeComponent === "Settings" ? "active" : ""}
          onClick={() => setActiveComponent("Settings")}
        >
          Settings
        </a>
        <a
          href="/#"
          className={activeComponent === "Export" ? "active" : ""}
          onClick={() => setActiveComponent("Export")}
        >
          Export
        </a>
      </div>
    </div>
  );
}

export default App;
