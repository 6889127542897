import React, { useState, useEffect, DetailedHTMLProps } from "react";
import "./SettingsComponent.css";

function SettingsComponent() {
  // State to manage text size and theme
  const [textSize, setTextSize] = useState("medium");
  const [lightMode, setLightMode] = useState(false);

  // Apply text size and theme based on state changes
  useEffect(() => {
    document.documentElement.style.fontSize = getTextSize(textSize);
    document.body.className = lightMode ? "light-mode" : "dark-mode";
  }, [textSize, lightMode]);

  // Function to map text size state to actual CSS size
  const getTextSize = (size : string) => {
    switch (size) {
      case "small":
        return "11px";
      case "large":
        return "24px";
      default:
        return "16px";
    }
  };

  // Function to handle text size change
  const handleTextSizeChange = (event : React.ChangeEvent<HTMLSelectElement>) => {
    setTextSize(event.target.value);
  };

  // Function to handle dark mode toggle
  const handleDarkModeToggle = () => {
    setLightMode(!lightMode);
  };

  return (
    <div className="settings-container">
      <h2>Settings</h2>

      <div className="setting-item">
        <label htmlFor="text-size">Text Size:</label>
        <select id="text-size" value={textSize} onChange={handleTextSizeChange}>
          <option value="small">Small</option>
          <option value="medium">Medium</option>
          <option value="large">Large</option>
        </select>
      </div>

      <div className="setting-item">
        <label htmlFor="dark-mode">Light Mode:</label>
        <input
          type="checkbox"
          id="dark-mode"
          checked={lightMode}
          onChange={handleDarkModeToggle}
        />
      </div>
    </div>
  );
}

export default SettingsComponent;
